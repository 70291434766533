import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import ImageLazyLoad from '../shared/ImageLazyLoad';
// Components & Utils
import HoverCard from './HoverCard';

// UI
import DefaultImage from '../../assets/events/fallBackEventImage.png';
import { Box } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import { moneyFormat } from '../../utils/util';
import { logAnalyticsEvent, logAnalyticsSelectContent } from '../../firebase';

const textContentStyles = {
  color: (t) => t.palette.grey[900],
  mt: 0,
  mb: '2px',
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  fontWeight: 200,
};

/**
 * @function BoxCard
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const BoxCard = ({ boxData }) => {
  const {
    pictureUrl,
    boxZone,
    number,
    availableSeats,
    price, // El precio del box completo
    pricePerTicket, // Puede o no vernir, el precio por asiento
    overlayText,
    id,
  } = boxData;
  const { eventID } = useParams();
  const [isCardHover, setIsCardHover] = useState(false);

  const onCardClick = () => {
    logAnalyticsSelectContent('box', id);
    logAnalyticsEvent({
      eventName: 'view_box_event',
      params: {
        box_id: id,
        event_id: eventID,
      },
    });
  };

  return (
    <Link
      to={`box/${id}`}
      onClick={onCardClick}
      style={{ color: 'inherit', textDecoration: 'none' }}>
      <Card
        elevation={0}
        onMouseEnter={() => {
          setIsCardHover(true);
        }}
        onMouseLeave={() => {
          setIsCardHover(false);
        }}
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          borderRadius: '16px',
          bgcolor: 'transparent',
          height: '100%',
        }}>
        <CardActionArea
          sx={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
          <CardMedia
            sx={{
              borderRadius: '16px',
              display: 'flex',
              flex: 1,
              alignSelf: 'stretch',
            }}>
            <Box sx={{ position: 'relative', flex: 1 }}>
              <ImageLazyLoad
                objectFitImage='cover'
                srcHighQuality={pictureUrl || DefaultImage}
                altText={`Palco ${boxZone}, Zona ${boxZone}`}
                containerStyles={{
                  borderRadius: { xs: '16px 0 0 16px', md: '16px 16px 0 0' },
                  overflow: 'hidden',
                  maxHeight: { xs: '14em', md: '14em' },
                  height: { xs: '14em', md: '14em' },
                }}
              />
              {overlayText && (
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background:
                      ' linear-gradient(180deg, rgba(35, 26, 60, 0.7) -37.86%, rgba(90, 60, 180, 0.8) 56.82%, rgba(35, 26, 60, 0.7) 132.57%)',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'MuseoModerno',
                      fontWeight: 800,
                      fontSize: { ms: '1.1rem', md: '1.5rem !important' },
                    }}>
                    {overlayText}
                  </Typography>
                </Stack>
              )}
            </Box>
          </CardMedia>
          <CardContent
            sx={{
              display: 'flex',
              backgroundColor: (t) => `${t.palette.grey[50]}`,
              width: '100%',
              flexDirection: 'column',
              flex: 1,
              borderRadius: '0 0 16px 16px !important',
            }}>
            <>
              <Typography
                sx={{
                  color: (t) => t.palette.grey[900],
                  fontWeight: 500,
                }}
                gutterBottom
                variant='body1'>{`${boxZone}, ${number}`}</Typography>

              {availableSeats && (
                <Typography
                  variant='body2'
                  sx={{
                    ...textContentStyles,
                  }}>{`${availableSeats} asientos`}</Typography>
              )}
              {price && (
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='body2' sx={{ ...textContentStyles }}>
                    Precio por evento
                  </Typography>
                  <Typography variant='body2' sx={{ ...textContentStyles }}>
                    {moneyFormat(price, true)}
                  </Typography>
                </Stack>
              )}
              {pricePerTicket ? (
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='body2' sx={{ ...textContentStyles }}>
                    Precio por asiento
                  </Typography>
                  <Typography variant='body2' sx={{ ...textContentStyles }}>
                    {moneyFormat(pricePerTicket, true)}
                  </Typography>
                </Stack>
              ) : (
                <></>
              )}
            </>
          </CardContent>
        </CardActionArea>
        <HoverCard eventTitle={`${boxZone}, ${number}`} display={isCardHover} />
      </Card>
    </Link>
  );
};

BoxCard.defaultProps = {
  something: '',
};

BoxCard.propTypes = {
  something: PropTypes.string,
};

export default BoxCard;
