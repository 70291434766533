export const menuOptions = {
  LANDING: {
    id: 'landing',
    label: 'Inicio',
    path: '/',
  },
  HOWITWORKS: {
    id: 'how-it-works',
    label: '¿Cómo funciona?',
    path: '/#how-it-works',
  },
  BOOKING: {
    id: 'booking',
    label: '¿Cómo rentar?',
    path: '/#booking',
  },
  ENTAILMENT: {
    id: 'entailment',
    label: 'Vinculación',
    path: '/#entailment',
  },
  SECURITY: {
    id: 'security-features',
    label: 'Seguridad',
    path: '/security-features',
  },
  FORMULA1: {
    id: 'formula1',
    label: 'Gran Premio CDMX',
    path: '/granpremio-cdmx',
  },
  NFLMEXICO: {
    id: 'NFLMexico',
    label: 'NFL México',
    path: '/nfl-mexico',
  },
  MUNDIAL2026: {
    id: 'MundialMexico2026',
    label: 'Mundial México 2026',
    path: '/mundial-mexico',
  },
  FAQ: {
    id: 'faq',
    label: 'Ayuda',
    path: '/faq',
  },
  TERMSANDCONITIONS: {
    id: 'terms&Conditions',
    label: '',
    path: '/terms-and-conditions',
  },
  PRIVACY: {
    id: 'privacy',
    label: '',
    path: '/privacy',
  },
  UNSUBSCRIBE: {
    id: 'unsubscribe',
    label: '',
    path: '/unsubscribe',
  },
  LOGIN: {
    id: 'login',
    label: '',
    path: '/login',
  },
  EVENTS: {
    id: 'events',
    label: 'Renta un palco',
    path: '/events',
  },
  BOX: {
    id: 'box',
    label: '',
    path: '/box',
  },
  FORGOTPASSWORD: {
    id: 'forgotPassword',
    label: '',
    path: '/reset-password',
  },
  SIGNUP: {
    id: 'signup',
    label: '',
    path: '/signup',
  },
  ACCOUNTVERIFICATION: {
    id: 'accountVerification',
    label: '',
    path: '/activate-account',
  },
  PROFILE: {
    id: 'profile',
    label: '',
    path: '/profile',
  },
  ADDRESSES: {
    id: 'addresses',
    label: '',
    path: 'addresses',
  },
  BOOKINGS: {
    id: 'bookings',
    label: '',
    path: 'bookings',
  },
  FAVORITES: {
    id: 'favorites',
    label: '',
    path: 'favorites',
  },
  BLOG: {
    id: 'blog',
    label: 'Blog',
    isExternal: true,
    path: 'https://blog.oasisapp.mx/',
  },
};

export const navbarOptions = [
  menuOptions.LANDING,
  menuOptions.EVENTS,
  menuOptions.SECURITY,
  menuOptions.FAQ,
  menuOptions.FORMULA1,
  menuOptions.MUNDIAL2026
];

export const landingOptions = [
  menuOptions.HOWITWORKS.id,
  menuOptions.BOOKING.id,
  menuOptions.ENTAILMENT.id,
];

export const footerMenuOptions = [
  menuOptions.HOWITWORKS,
  menuOptions.BOOKING,
  menuOptions.SECURITY,
  menuOptions.FORMULA1,
  menuOptions.MUNDIAL2026,
  menuOptions.FAQ,
  menuOptions.BLOG,
];

export const transparentNavMenuOptions = [menuOptions.FORMULA1.path];
