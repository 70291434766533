import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components & Utils
import ImageLazyLoad from '../../components/shared/ImageLazyLoad';
import IconText from '../../components/shared/IconText';
import { moneyFormat, parseDate } from '../../utils/util';

// UI
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import DefaultImage from '../../assets/events/fallBackEventImage.png';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StatusChip from '../../components/shared/StatusChip';
import moment from "moment";

/**
 * @function BookingCard
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const BookingCard = ({ booking }) => {
  const { event, box, total, status, totalSeats } = booking;
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const onCardClick = () => {
    navigate(`${booking.id}`);
  };

  // TODO: Season Bookings
  if (booking.boxType !== 'onetime') {
    return <></>;
  }

  return (
    <Stack
      onClick={onCardClick}
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='flex-start'
      sx={{
        cursor: 'pointer',
        borderRadius: '16px',
        backgroundColor: (t) => t.palette.grey[50],
        transition: 'background-color 250ms',
        ':hover': {
          boxShadow: (t) => `3px 3px 30px 10px ${t.palette.shadowBlack}`,
          backgroundColor: (t) => t.palette.grey[100],
        },
        ':active': {
          backgroundColor: (t) => t.palette.grey[200],
        },
      }}>
      <Box>
        <ImageLazyLoad
          objectFitImage='cover'
          srcHighQuality={event?.pictureUrl || DefaultImage}
          altText={event?.name || ''}
          containerStyles={{
            borderRadius: isMobile ? '16px 16px 0 0' : '16px 0 0 16px',
            maxHeight: { xs: '10em', md: '14em' },
            height: { xs: '10em', md: '14em' },
            overflow: 'hidden',
            ...(!isMobile && {
              width: '22em',
            }),
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          p: isMobile ? 2 : 2.5,
          borderRadius: isMobile ? '0 0 16px 16px' : '0 16px 16px 0',
          width: '100%',
        }}>
        <Stack justifyContent='space-between' sx={{ height: '100%' }}>
          <Stack spacing={0.5} sx={{ mb: 1 }}>
            <Typography
              noWrap
              sx={{
                color: (t) => t.palette.grey[900],
                fontWeight: 400,
                whiteSpace: 'nowrap',
              }}
              variant='h6'>
              {booking.event?.name}
            </Typography>
            <IconText
              text={event?.venue?.name}
              Icon={<LocationOnIcon color='secondary' />}
              typographyProps={{
                variant: isMobile ? 'body2' : 'body1',
                sx: { color: 'common.black' },
              }}
            />
            {event.startsAt && (
              <IconText
                text={parseDate(
                  moment(event.startsAt).add(5, 'hours').toString(),
                  isMobile ? 'dayMonthYearTime' : 'dayLongMonthYearTime'
                )}
                Icon={<CalendarTodayIcon color='secondary' />}
                typographyProps={{
                  variant: isMobile ? 'body2' : 'body1',
                  sx: { color: 'common.black' },
                }}
              />
            )}
            {totalSeats && (
              <IconText
                text={`${totalSeats} asientos`}
                Icon={<EventSeatIcon color='secondary' />}
                typographyProps={{
                  variant: isMobile ? 'body2' : 'body1',
                  sx: { color: 'common.black' },
                }}
              />
            )}
            <Typography
              noWrap
              variant='body1'
              sx={{ color: 'common.black', whiteSpace: 'nowrap' }}>
              {`Palco ${box?.number} Zona ${box?.boxZone}`}
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              noWrap
              variant={isMobile ? 'body2' : 'body1'}
              sx={{ color: 'common.black' }}>
              {moneyFormat(Number(total))}
            </Typography>
            <Box>
              <StatusChip variant={status} />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

BookingCard.defaultProps = {
  something: '',
};

BookingCard.propTypes = {
  something: PropTypes.string,
};

export default BookingCard;
