import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

// Components & Utils
import SearchBar from '../../components/shared/SearchBar';
import FAQSection from '../../components/shared/FAQSection';
import DownloadAppSection from '../../components/shared/DownloadAppSection';
import { AuthContext } from '../../providers/AuthProvider';
// UI
import { Box, Typography } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import FansImage from '../../assets/others/fans.svg';
import ViewContainer from '../../components/shared/ViewContainer';
import HalfCircle from '../../components/shared/HalfCircle';
import EventsPerCategory from './EventsPerCategory';
import EventsBySearch from './EventsBySearch';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import BannersCarousel from '../../components/landing/BannersCarousel';
//import { nosotrosData } from '../../utils/nosotrosData';
//import BannersCarousel from '../../components/landing/BannersCarousel';
import { sponsorsData } from '../../utils/sponsorsData';

/**
 * @function Events
 * @author RCH010
 * @version 1.0
 * @description Events view
 * @returns {JSX}
 */
const Events = () => {
  const { auth } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  useDocumentTitle('Oasis App - Encuentra un evento');

  const searchEventQuery = searchParams.get('search_event');
  const searchCategoryQuery = searchParams.get('category');
  const searchCategoryName = searchParams.get('name');

  return (
    <ViewContainer>
      <HalfCircle isLeftCircle top='250px' left='-2em' maxWidth='unset' />
      <HalfCircle right='-2em' bottom='200px' opacity='.8' maxWidth='unset' />

      <Box
        sx={{
          px: 3,
          mb: 10,
          maxWidth: 'lg',
          mx: 'auto',
          zIndex: 1,
          position: 'relative',
        }}>
        <Box sx={{ mt: 20, mb: 10 }}>
          {auth.userData ? (
            <Typography variant='h1'>
              Hola {auth?.userData?.firstName}
            </Typography>
          ) : (
            ''
          )}
          <Typography variant='h1'>Encuentra tu evento favorito</Typography>
          <Typography variant='h3'>y vive una experiencia VIP</Typography>
        </Box>
        <Box
          sx={{
            '& > img': {
              maxWidth: '100%',
              mb: -2,
            },
          }}>
          <img src={FansImage} alt='' />
          <SearchBar />
        </Box>
      </Box>
      {/* AQUI IRA EL CARRUSEL DE REDES */}
      {/*<BannersCarousel
        title='Conoce a nuestros patrocinadores'
        data={sponsorsData}
        variantTitle='h2'
        bkDesk={1}
        bkTablet={1}
        bkMobile={1}
      />*/}
      {searchEventQuery || searchCategoryQuery ? (
        <EventsBySearch
          search={searchEventQuery}
          category={searchCategoryQuery}
          categoryName={searchCategoryName}
        />
      ) : (
        <EventsPerCategory />
      )}

      <Box sx={{ mb: 15, position: 'relative', zIndex: 1 }}>
        <DownloadAppSection />
      </Box>
      <FAQSection />
    </ViewContainer>
  );
};

export default Events;
