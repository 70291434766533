import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box } from '@mui/material';

/**
 * @function BannerItem
 * @author Axel
 * @version 1.0
 */
const BannerItem = ({ image, alt, link, widthImg }) => {
  return (
    <Box
      sx={{
        p: 5,
        height: '100%',
        maxWidth: '100%',
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center'
      }}>
      <a href={link} target='_blank' rel="noreferrer">
        <img src={image} alt={alt} style={{maxWidth: widthImg}}/>
      </a>
    </Box>
  );
};

BannerItem.defaultProps = {
  image: '',
  alt: '',
  link: '',
  widthImg: '100%'
};

BannerItem.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
  widthImg: PropTypes.string
};

export default BannerItem;