import React from 'react';

// UI
import { Box, Stack } from '@mui/material';

/**
 * @function FormContainer
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const FormContainer = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        pt: { xs: 3, md: 7 },
        minHeight: { xs: '24em', md: '30em' },
        display: 'flex',
      }}>
      <Stack
        spacing={4}
        justifyContent='space-between'
        sx={{ flexGrow: 1, maxWidth: '100%' }}>
        {children}
      </Stack>
    </Box>
  );
};

export default FormContainer;
